.topbar {
  background-color: var(--border-gray);
  padding: 6px 10px;
  border-radius: 8px 8px 0 0;
  color: var(--black);
  font-size: var(--font-sm);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filename {
  font-size: var(--font-sm);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.actionText {
}

.iconText {
  font-size: var(--font-xs);
  font-weight: var(--font-weight-medium);
  display: flex;
  align-items: center;
  padding: 4px 8px;
  background-color: var(--icon-bg-grey);
  width: fit-content;
  border-radius: var(--br-xxs);
  gap: 4px;
  flex: none;
}

.iconText:hover {
  cursor: pointer;
  background-color: var(--white);
}
