.cta {
  padding: 10px 18px;
  text-align: center;
  border-radius: var(--br-xxs);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-sm);
  border: none;
  text-align: center;
  cursor: pointer;
  color: var(--black);
  transition: all 0.5s ease;
  background-color: var(--yellow);

  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
}

.cta:hover {
  box-shadow: var(--section-card-box-shadow);
}
