.heading {
  text-align: center;
}

.score {
  font-weight: var(--font-weight-medium);
  font-size: var(--font-2xl);
  text-align: center;
  margin: 10px auto;
}

.desc {
  font-weight: var(--font-weight-medium);
  font-size: var(--font-2xl);
  text-align: center;
  color: var(--txt-color);
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .desc {
    font-size: var(--font-lg);
  }
}
