.sections-wrapper {
  --space-from-top: 5rem;
  display: flex;
  flex-wrap: nowrap;
  max-width: var(--page-width);
  gap: var(--page-gap);
  margin: var(--page-margin) auto;
}

.sections-wrapper > * {
  margin-bottom: var(--section-gap);
}

.fixedSections {
  width: calc(100% - 32px);
}

.sections,
.right-sticky-sections {
  display: flex;
  flex-direction: column;
  gap: var(--section-gap);
}

.sections {
  height: 100%;
  width: var(--main-layout-width);
  border-radius: var(--br-xs);

  padding: var(--main-layout-padding);
  border: var(--main-layout-border, 1px) solid var(--theme-blue);
  background-color: var(--main-layout-bg, transparent);
}

.right-sticky-sections {
  display: none;
  position: sticky;
  right: 0;
  top: var(--space-from-top);
  height: calc(100vh - var(--space-from-top));
  overflow-y: auto;
  padding-right: 1rem;
}

@media screen and (max-width: 768px) {
  .sections {
    width: initial;
  }

  .sections-wrapper {
    margin: var(--page-margin);
    flex-direction: column;
    --main-layout-width: 100% !important;
    --right-sticky-layout-width: 100% !important;
  }

  .right-sticky-sections {
    position: static;
    max-width: none;
    padding: 0;
  }
}
